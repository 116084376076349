"use client";
import "./globals.css";
import "../styles.css";
import { UserProvider } from "./utils/user";
import { Toaster } from "react-hot-toast";
import BugsnagPluginReact, {
  BugsnagPluginReactResult,
} from "@bugsnag/plugin-react";
import Bugsnag from "@bugsnag/js";
import React, { Fragment, useEffect } from "react";
import ReactGA from "react-ga4";

// export const metadata: Metadata = {
//   title: "Fundraising Portal",
//   description: "Generated by create next app",
// };
Bugsnag.start({
  apiKey: "44a2f7cad259b9e33b3e6b73923c2d6d",
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production"],
  releaseStage: `${process.env.NODE_ENV}`,
  logger: null,
});
const bugsnagPluginReactResult = Bugsnag.getPlugin(
  "react"
) as BugsnagPluginReactResult;
const ErrorBoundary = bugsnagPluginReactResult.createErrorBoundary(React);

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  ReactGA.initialize("G-T5ZT24QSBS");

  useEffect(() => {
    const scriptTermlyConfig = document.createElement("script");

    scriptTermlyConfig.innerHTML = `window.TERMLY_CUSTOM_BLOCKING_MAP = {
      "google-analytics.com": "analytics",
      "localhost": "essential",
      "innovator.org": "essential",
      "innovator.imgix.net": "essential",
      "intercom.io": "essential",
      "intercomcdn.com": "essential",
      "fonts.gstatic.com": "essential",
      "medtechinnovator.org": "essential",
    };`;
    scriptTermlyConfig.setAttribute("data-termly-config", "");
    document.body.appendChild(scriptTermlyConfig);

    const script = document.createElement("script");

    script.src =
      "https://app.termly.io/resource-blocker/f7e035d1-4793-45d7-be62-710eb8b97444?autoBlock=on";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <ErrorBoundary>
      <html lang="en" className="h-full bg-white">
        <head>
          <title>Innovator Portal</title>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="anonymous"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
            rel="stylesheet"
          />
        </head>
        <body className="font-body min-h-screen flex flex-col">
          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: "",
              duration: 3000,
              style: {
                background: "#363636",
                color: "#fff",
              },
              success: {
                duration: 3000,
              },
            }}
          />
          <UserProvider>{children}</UserProvider>
          {/* Makes no sense having the footer in here but otherwise next complains */}
          <footer className="bg-white mt-auto">
            <div className="mx-auto max-w-4xl overflow-hidden px-16 pb-8 pt-8 sm:px-32">
              <div className="relative">
                <div
                  aria-hidden="true"
                  className="absolute inset-0 flex items-center"
                >
                  <div className="w-full border-t border-gray-200" />
                </div>
              </div>
            </div>
            <div className="mx-auto max-w-7xl overflow-hidden px-6 pb-6 sm:pb-6 lg:px-8">
              <nav
                className="mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12 text-sm leading-6 text-gray-600"
                aria-label="Footer"
              >
                <div>
                  <a href="/about-us/" className="hover:text-gray-900">
                    About
                  </a>
                </div>
                <div>
                  <a
                    href="https://medtechinnovator.org/privacy/"
                    className="hover:text-gray-900"
                  >
                    Privacy
                  </a>
                </div>
                <div>
                  <a
                    href="https://medtechinnovator.org/terms-and-conditions/"
                    className="hover:text-gray-900"
                  >
                    Terms & Conditions
                  </a>
                </div>
                <div className="hover:text-gray-900">
                  <a href="#" className="termly-display-preferences">
                    Consent Preferences
                  </a>
                </div>
                <div>
                  <a
                    href="https://medtechinnovator.org/contact/"
                    className="hover:text-gray-900"
                  >
                    Contact Us
                  </a>
                </div>
              </nav>
              <div className="mt-10 flex justify-center space-x-10">
                <a
                  href="https://www.linkedin.com/company/medtech-innovator"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Linked In</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
                </a>

                <a
                  href="https://www.youtube.com/@Medtechinnovatorchannel"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">YouTube</span>
                  <svg
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </div>
              <p className="mt-10 text-center text-xs leading-5 text-gray-500">
                &copy; {new Date().getFullYear()} MedTech Innovator, All rights
                reserved.
              </p>
            </div>
          </footer>
        </body>
      </html>
    </ErrorBoundary>
  );
}
